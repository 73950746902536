<template>
  <div>
    <LiefengContent>
      <template v-slot:title>{{ "社区组织动员体系数据初始化" }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true">
            <FormItem>
                <LiefengCascaderCity  ref="cascader" :orgLimit="440103" :width="200" @changeCasader="changeCasader" :isClear="false" :CascaderList="search.cascaderIdList" orgCode="4401"></LiefengCascaderCity>
            </FormItem>
            <!-- <FormItem>
                <Checkbox v-model="search.auth" true-value="1" false-value="" style="margin-right:10px">已初始实名</Checkbox>
                <Checkbox v-model="search.org" true-value="1" false-value="" style="margin-right:10px">已初始社区架构</Checkbox>
                <Checkbox v-model="search.grid" true-value="1" false-value="" style="margin-right:10px">已初始网格党小组</Checkbox>
                <Checkbox v-model="search.party" true-value="1" false-value="" style="margin-right:10px">已初始网格党支部</Checkbox>
                <Checkbox v-model="search.peo" true-value="1" false-value="" style="margin-right:10px">已初始民情档案</Checkbox>
            </FormItem> -->
            <Button type="primary" style="margin-right:10px" @click="searchBtn" >查询</Button>
            <Button type="primary" v-if="custGlobalId == 'C2107281452198a9dc64d472386'" style="margin-right:10px" :loading="synchroLOading"  @click="synchro" >批量同步民情档案</Button>
            <Button type="info" style="margin-right:10px" @click="exportExcel">导出</Button>
            <Button type="primary" style="margin-right:10px" @click="initializeAlbum">同步至居民数据库（{{tableSelectNum}}）</Button>
            
        </Form>
      </template>
      <template v-slot:contentArea>
        <LiefengTable
          :talbeColumns="table.talbeColumns"
          :tableData="table.tableData"
          :loading="table.loading"
          :fixTable="true"
          :curPage="table.page"
          :total="table.total"
          :pagesizeOpts="[100, 150, 200, 300]"
          :page-size="table.pageSize"
          @hadlePageSize="hadlePageSize"
          @tableSelect="tableSelect"
        ></LiefengTable>
      </template> 
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengCascaderCity from '@/components/LiefengCascaderCity'
import {initalMixin} from './initalMixin'
export default {
  components: { LiefengContent, LiefengTable, LiefengModal ,LiefengCascaderCity},
  mixins:[initalMixin],
  data() {
    return {
      search:{
          cascaderIdList:[],
          auth:'',
          org:'', 
          grid:'',
          party:'',
          peo:'',
          orgList:[],
          orgName:'',
          synchroLOading:false,
          custGlobalId:'',
      },
     
    };
  },
 async created(){ 
    this.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId,
    this.$nextTick(()=>{
      this.$refs.cascader.CascaderId = this.search.cascaderIdList
    })  
    this.search.cascaderIdList = ['440103']
    await this.getList()
  },
  methods: {
    // 方法部分
    // 切换分页事件
    hadlePageSize(val) {
      this.table.page = val.page;
      this.table.pageSize = val.pageSize;
      this.getList();
    },

    // 查询按钮
    searchBtn(){
        this.table.page = 1;
        this.getList()
    },
        
    // 选择社区事件
    changeCasader(val){
        this.search.cascaderIdList = val
    },
    // 关闭弹窗事件
    excelModalData(status){
       
    },

    // 打开弹窗事件
    openModal(){
       
    },

  },
};
</script>

<style lang="less" scoped>

.export-modal{
  /deep/ #modal_contentarea{
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
    
  }
}

</style>